import BaseTemplate from "../Components/BaseTemplate"
import NiceLink from "../Components/NiceLink"
import { Helmet } from "react-helmet"

function ProjectsPage() {
    return (
        <BaseTemplate currentRoute = "Projects">
            <Helmet>
                <title>Projects - Andreas Mitillos</title>
            </Helmet>

            <div className="mb-4">
                <h1 className='font-bold text-4xl'>Projects</h1>
                <p className='text-gray-500'>Find my projects listed below, as well as their code:</p>
            </div>

            <div className="dark:border-gray-500 pb-3 pt-4">
                <p className="mono font-bold mb-3">(Backtesting) Pairs Trading - Cointegration</p>
                <NiceLink link={false} href="https://github.com/andreasmitillos" text="GitHub (To Come)" />
                <NiceLink link={true} href={`/projects/backtesting-pairs-trade-cointegration`} text="Web App" />
            </div>

            <div className="border-t dark:border-gray-500 pb-3 pt-4">
                <p className="mono font-bold mb-3">(Analysing) Pairs Trading - Cointegration</p>
                <p className="mb-3 text-gray-500">
                    Pairs trading is a mean-reverting, market-neutral trading strategy that involves statistical and mathematical concepts such as Stationarity, Integration and Cointegration. <br/><br/>
                    The general principle is to identify two securities, such as PEP and KO, that have an underlying economic relationship and exhibit a high likelihood of being cointegrated. This means that while the prices of X and Y may move independently in the short term, the spread between them tends to revert to a long-term mean. <br/> <br/>
                    When implementing a pairs trade, one security is bought (long position) while the other is sold short (short position). The position are established when the spread between the two securities deviates significantly from its historical mean. The expectation is that the spread will eventually revert to its mean, allowing the trade to close both positions at a profit when the spread narrows. <br/><br/>
                    The key to profitability in pairs trading is the assumption that the relationship between the two securities is stable over time, and that any divergence is temporary. Profit is realised when the spread between the two securities reverts to its mean, allowing the trader to close the positions in a way that captures the price convergence.
                </p>
                <NiceLink link={false} href="https://github.com/andreasmitillos" text="GitHub (To Come)" />
                <NiceLink link={true} href={`/projects/analysing-pairs-trade-cointegration`} text="Web App" />
            </div>
        </BaseTemplate>
    )
}

export default ProjectsPage