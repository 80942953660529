import BaseTemplate from "../Components/BaseTemplate"

function ErrorPage(props) {
    return (
        <BaseTemplate currentRoute = "Projects">
            <div className="mb-4">
                <h1 className='font-bold text-4xl'>This page was not found... :(</h1>
            </div>
        </BaseTemplate>
    )
}

export default ErrorPage