import BaseTemplate from "../Components/BaseTemplate"
import NiceLink from "../Components/NiceLink"
import { Helmet } from "react-helmet"

function HomePage(props) {
    return (
        <BaseTemplate currentRoute = "Home">
            <Helmet>
                <title>Home - Andreas Mitillos</title>
            </Helmet>
            <div className="mb-8">
                <h1 className='text-4xl font-bold'>Hi. I'm Andreas Mitillos.</h1>
                <p className="mono text-gray-500 text-sm">BSc MORSE and MSc Mathematical Finance @ University of Warwick</p>
            </div>

            <NiceLink link={false} href="https://linkedin.com/in/andreasmitillos" text="Conntect on LinkedIn" />            
        </BaseTemplate>
    )
}

export default HomePage