import BaseTemplate from "../Components/BaseTemplate"
import NiceLink from "../Components/NiceLink"
import { Helmet } from "react-helmet"

function ResumePage() {
    return (
        <BaseTemplate currentRoute = "Resume">
            <Helmet>
                <title>Resume - Andreas Mitillos</title>
            </Helmet>
            <div className="mb-8">
                <h1 className='text-4xl font-bold'>Resume</h1>

                <NiceLink link={false} href="Andreas Mitillos_Resume.pdf" text="PDF Resume" />            
            </div>
            <embed src="Andreas Mitillos_Resume.pdf" className="w-full h-140 min-h-screen" />
        </BaseTemplate>
    )
}

export default ResumePage