import BaseTemplate from "../Components/BaseTemplate"

import { MathJax, MathJaxContext } from "better-react-mathjax"

function BlogPost(props) {
    return (
        <BaseTemplate currentRoute = "Blog">
            <h1 className='font- text-2xl font-serif font-bold'>Analysing the Profitability of the Cointegration Pairs Trading Strategy</h1>
            <p className="dark:text-slate-500 text-sm">By Andreas Mitillos - 21 August 2024</p>
            <img src="/bg-2.jpg" className="my-6 w-max" />
            {/* <p className='text-gray-500'>BSc MORSE and MSc Mathematical Finance @ University of Warwick</p> */}
            

            <p className="mt-6">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Est labore sit maiores, odio doloribus unde alias vero facere, reprehenderit, nihil exercitationem aliquid assumenda quis ducimus! Sequi voluptatem commodi ex natus?
            </p>

            <p className="mt-6">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Est labore sit maiores, odio doloribus unde alias vero facere, reprehenderit, nihil exercitationem aliquid assumenda quis ducimus! Sequi voluptatem commodi ex natus? Lorem ipsum dolor, sit amet consectetur adipisicing elit. Corporis, veniam officia amet mollitia repellat quaerat sunt, quisquam quibusdam, sint laborum et iste ea. Rem laborum explicabo accusantium sint dicta voluptate.
            </p>

            <img src="/photo-1.jpg" className="my-6 w-max" />

            <p className="mt-6">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Est labore sit maiores, odio doloribus unde alias vero facere, reprehenderit, nihil exercitationem aliquid assumenda quis ducimus! Sequi voluptatem commodi ex natus? 
            </p>

            <div className="text-center mt-6">
                <MathJaxContext>
                    {/* <MathJax>{"\\(\\frac{10}{4x} \\approx 2^{12}\\)"}</MathJax> */}
                    <MathJax>{"\\( \\begin{align} 1 + 1 &= 5x \\\\ 1 + 1 + 1 + 1 &= 5 \\  \\end{align} \\)"}</MathJax>
                </MathJaxContext>
            </div>

            <p className="mt-6">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Est labore sit maiores, odio doloribus unde alias vero facere, reprehenderit, nihil exercitationem aliquid assumenda quis ducimus! Sequi voluptatem commodi ex natus? 
            </p>
        </BaseTemplate>
    )
}

export default BlogPost