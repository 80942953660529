import { useParams, useSearchParams } from "react-router-dom";
import BaseTemplate from "../Components/BaseTemplate";
import { ArrowRightIcon } from "@heroicons/react/24/solid";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet"
import annotationPlugin from "chartjs-plugin-annotation"

import { 
    Chart as ChartJS,
    LineElement,
    CategoryScale,
    LinearScale,
    PointElement,
    Tooltip,
    Title,
    Legend
 } from "chart.js"
import { Line } from "react-chartjs-2"

ChartJS.register(
    LineElement,
    CategoryScale,
    LinearScale,
    PointElement,
    Tooltip,
    Title,
    Legend,
    annotationPlugin
)

function TimeseriesChart(props) {
    const show = props.show
    console.log(props.data)
    if (show && props.data?.labels) {
        return <Line
        data = {props.data}
        options = {props.options}
    ></Line>
    } else {
        return <div>not show</div>
    }
}

function AnalysingPairsTradeProject(props) {
    let { projectId } = useParams()

    let baseUrl = "https://api-prod-v1.mitillos.me"
    let devUrlOverride = false

    if ((!process.env.NODE_ENV || process.env.NODE_ENV === "development") && !devUrlOverride) { 
        baseUrl = "http://127.0.0.1:5000"
    }

    const [loading, setLoading] = useState(false)

    const [inputs, setInputs] = useState({})
    const [oldInputs, setOldInputs] = useState({});

    // Get Search Params
    const [searchParams, setSearchParams] = useSearchParams()

    const [data, setData] = useState({})

    const [showData, setShowData] = useState(false)

    const [apiError, setApiError] = useState(false)

    const onChange = e => {
        setInputs({ ...inputs, [e.target.name]: e.target.value })
        setSearchParams({ ...inputs, [e.target.name]: e.target.value })
    }

    useEffect(() => {
        let stock_a_query = searchParams.get("stock_a")
        let stock_b_query = searchParams.get("stock_b")

        let start_date_query = searchParams.get("start_date")
        let end_date_query = searchParams.get("end_date")

        let newInputs = {
            ...inputs
        }

        if (stock_a_query) newInputs["stock_a"] = stock_a_query
        if (stock_b_query) newInputs["stock_b"] = stock_b_query

        if (start_date_query) newInputs["start_date"] = start_date_query
        if (end_date_query) newInputs["end_date"] = end_date_query

        setInputs(newInputs)

        if (stock_a_query && stock_b_query && start_date_query && end_date_query ) {
            getJoke(newInputs)
        }
    }, []);

    const [priceData, setPriceData] = useState({})
    const [residualData, setResidualData] = useState({})

    const getJoke = (customInputs) => {
        let myInputs = inputs

        if (customInputs && customInputs["stock_a"]) myInputs = customInputs

        setApiError(false)
        setData({})
        setOldInputs(myInputs)

        let alreadyError = false

        if (!myInputs["stock_a"]) {
            setApiError("Stock A not defined")
            alreadyError = true
        }

        if (!myInputs["stock_b"] && !alreadyError) {
            setApiError("Stock B not defined")
            alreadyError = true
        }

        if (!myInputs["start_date"] && !alreadyError) {
            setApiError("Start Date not defined")
            alreadyError = true
        }

        if (!myInputs["end_date"] && !alreadyError) {
            setApiError("End Date not defined")
            alreadyError = true
        }

        if (!alreadyError) {
            setLoading(true)
            setShowData(false)

            fetch(`${baseUrl}/pairs_trade/analyse/${myInputs["stock_a"]}/${myInputs["stock_b"]}/${myInputs["start_date"]}/${myInputs["end_date"]}`)
                .then((response) => response.json())
                .then((data) => {
                    setLoading(false)
                    setData(data?.data)

                    if (data.error) {
                        setApiError(data.message)
                        setData({})
                        setPriceData({})
                        setResidualData({})
                        setShowData(false)
                    }
                })
                .catch(error => {
                    setData({})
                    setLoading(false)
                })
        }

    }

    let chartObject = <div></div>

    useEffect(() => {
        if (data && inputs && inputs["stock_a"] && data[inputs["stock_a"]] && inputs["stock_b"] && data[inputs["stock_b"]]) {
            setShowData(true)

            setPriceData({
                labels: data.dates,
                datasets: [
                    {
                        label: inputs["stock_a"],
                        data: data[inputs["stock_a"]],
                        backgroundColor: "rgb(108,114,127)",
                        pointStyle: false,
                        pointRadius: 1,
                        borderWidth: 1,
                        pointHoverRadius: 1,
                        borderColor: "rgb(108,114,127)",
                        yAxisID: 'y',
                    },
                    {
                        label: inputs["stock_b"],
                        data: data[inputs["stock_b"]],
                        backgroundColor: "rgb(78,128,238)",
                        pointStyle: false,
                        pointRadius: 1,
                        borderWidth: 1,
                        pointHoverRadius: 1,
                        borderColor: "rgb(78,128,238)",
                        yAxisID: 'y1',
                    }
                ]
            })

            setResidualData({
                labels: data.dates,
                datasets: [
                    {
                        label: "Residuals",
                        data: data["residuals"],
                        backgroundColor: "rgb(108,114,127)",
                        pointStyle: false,
                        pointRadius: 1,
                        borderWidth: 1,
                        pointHoverRadius: 1,
                        borderColor: "gray"
                    }
                ]
            })

            chartObject = <Line
                data = {priceData}
                options = {options}
            ></Line>

            setShowData(true)
        }
    }, [data]);

    const options = {
        responsive: true,
        scales: {
            y: {
                type: 'linear',
                display: true,
                position: 'left',
            },
            y1: {
                type: 'linear',
                display: true,
                position: 'right',
                // grid line settings
                grid: { drawOnChartArea: false }
            }
        }
    }

    return (
    <BaseTemplate currentRoute = "Projects">
        <Helmet>
            <title>{showData && oldInputs["stock_a"] && oldInputs["stock_b"] ? `${oldInputs["stock_a"]} / ${oldInputs["stock_b"]} ` : ""}Analysing Pairs Trade - Andreas Mitillos</title>
        </Helmet>

        <div className="mb-8">
            <h1 className='font-bold text-4xl'><span className="text-slate-500">Analysing</span> Pairs Trade - Cointegration</h1>
            <p className='text-gray-500 text-sm mono'>{projectId}</p>
        </div>

        <div className="mb-8">
            <h2 className="font-semibold text-2xl">1. Define Stock Tickers</h2>
            <p className="text-gray-500">Start by selecting the two stock tickers</p>
            <div className="sm:flex sm:space-x-6">
                <div className="sm:w-1/2 flex flex-col mt-2">
                    <label className="mb-1 text-sm font-semibold">Stock A Ticker</label>
                    <input name = "stock_a" onChange = {onChange.bind(this)} value = {inputs["stock_a"]} type="text" className="border-b mono pb-1 text-sm outline-none dark:bg-black dark:border-gray-700 focus:border-gray-500" placeholder="E.g., AMD" />
                </div>
                <div className="sm:w-1/2 flex flex-col mt-2">
                    <label className="mb-1 text-sm font-semibold">Stock B Ticker</label>
                    <input name = "stock_b" onChange = {onChange.bind(this)} value = {inputs["stock_b"]} type="text" className="border-b mono pb-1 text-sm outline-none dark:bg-black dark:border-gray-700 focus:border-gray-500" placeholder="E.g., NVDA" />
                </div>
            </div>
        </div>

        <div className="mb-8">
            <h2 className="font-semibold text-2xl">2. Define Backtesting Period</h2>
            <p className="text-gray-500">Define the dates for which the backtesting will run.</p>
            <div className="sm:flex sm:space-x-6">
                <div className="sm:w-1/2 flex flex-col mt-2">
                    <label className="mb-1 text-sm font-semibold">Start Date</label>
                    <input name = "start_date" onChange = {onChange.bind(this)} value = {inputs["start_date"]} type="date" className="border-b mono pb-1 text-sm outline-none dark:bg-black dark:border-gray-700 focus:border-gray-500" />
                </div>
                <div className="sm:w-1/2 flex flex-col mt-2">
                    <label className="mb-1 text-sm font-semibold">End Date</label>
                    <input name = "end_date" onChange = {onChange.bind(this)} value = {inputs["end_date"]} type="date" className="border-b mono pb-1 text-sm outline-none dark:bg-black dark:border-gray-700 focus:border-gray-500" />
                </div>
            </div>
        </div>

        <div className="bg-slate-100 py-3 px-2 border-t-4 mb-6 text-sm border-slate-500 text-slate-500 dark:text-slate-400 dark:bg-black">
            <h4 className="text-lg text-slate-500 font-medium">Notes</h4>
            <ul className="list-disc ml-6">
                <li>Yahoo Finance daily price data is used</li>
                <li>This web-application is intended for demonstration purposes ONLY</li>
                <li>Adjusted closing prices are used</li>
            </ul>
        </div>

        <div className="mb-8">
            {loading ? <button disabled className="bg-gray-700 dark:bg-gray-200 dark:text-black text-white text-sm px-4 py-2 cursor-not-allowed">
                <div class="mx-5 h-5 w-5 animate-spin rounded-full border-2 border-t-white border-gray-600 dark:border-t-black dark:border-gray-200" />
            </button> : <button onClick={getJoke} className="bg-black dark:bg-white dark:text-black text-white text-sm px-4 py-2 hover:underline inline-flex items-center">
                Analyse <ArrowRightIcon className="size-5 ml-2" />
            </button>}
        </div>

        {showData ? 
        <div className="my-4">
            <h1 className='font-bold text-4xl mb-4'>Analysis Report</h1>

            <div className="bg-slate-100 border-t-2 border-slate-500 py-3 px-5 text-sm text-slate-600 mb-4 dark:text-slate-400 dark:bg-black">
                <h3 className="text-lg font-bold">Stationarity Test - Augmented Dickey Fuller Tests</h3>

                {/* <hr className="mt-1 mb-2 border-slate-300 dark:bg-slate-800" /> */}
                
                <div className="mono">
                    {/* <p className="text-center my-2 bg-slate-200 rounded py-2 font-bold dark:text-slate-400 dark:bg-slate-800">{oldInputs["stock_a"]}(t) = alpha + beta * {oldInputs["stock_b"]}(t)</p> */}
                    <p>{oldInputs["stock_a"]} ADF p-value = {Math.round(data[oldInputs["stock_a"] + "_adf_p"] * 1000) / 1000}</p>
                    <div className="flex space-x-2 mt-2">
                        <div className={`flex-1 py-2 px-3 dark:text-slate-200 rounded ${data[oldInputs["stock_a"] + "_adf_p"] <= 0.05 ? "bg-red-200 dark:bg-red-800" : "bg-green-200 dark:bg-green-800"}`}>
                            95% Confidence <br/>
                            {data[oldInputs["stock_a"] + "_adf_p"] <= 0.05 ? oldInputs["stock_a"] + " ~ I(0)" : oldInputs["stock_a"] + " ~ I(1)"} <br/>
                            {data[oldInputs["stock_a"] + "_adf_p"] <= 0.05 ? "H0 Rejected" : "H0 Not Rejected"}
                        </div>
                        <div className={`flex-1 py-2 px-3 dark:text-slate-200 rounded ${data[oldInputs["stock_a"] + "_adf_p"] <= 0.01 ? "bg-red-200 dark:bg-red-800" : "bg-green-200 dark:bg-green-800"}`}>
                            99% Confidence <br/>
                            {data[oldInputs["stock_a"] + "_adf_p"] <= 0.01 ? oldInputs["stock_a"] + " ~ I(0)" : oldInputs["stock_a"] + " ~ I(1)"}<br/>
                            {data[oldInputs["stock_a"] + "_adf_p"] <= 0.05 ? "H0 Rejected" : "H0 Not Rejected"}
                        </div>
                    </div>

                    <p className="mt-2">{oldInputs["stock_b"]} ADF p-value = {Math.round(data[oldInputs["stock_b"] + "_adf_p"] * 1000) / 1000}</p>

                    <div className="flex space-x-2 mt-2">
                        <div className={`flex-1 py-2 px-3 dark:text-slate-200 rounded ${data[oldInputs["stock_b"] + "_adf_p"] <= 0.05 ? "bg-red-200 dark:bg-red-800" : "bg-green-200 dark:bg-green-800"}`}>
                            95% Confidence <br/>
                            {data[oldInputs["stock_b"] + "_adf_p"] <= 0.05 ? oldInputs["stock_b"] + " ~ I(0)" : oldInputs["stock_b"] + " ~ I(1)"} <br/>
                            {data[oldInputs["stock_b"] + "_adf_p"] <= 0.05 ? "H0 Rejected" : "H0 Not Rejected"}
                        </div>
                        <div className={`flex-1 py-2 px-3 dark:text-slate-200 rounded ${data[oldInputs["stock_b"] + "_adf_p"] <= 0.01 ? "bg-red-200 dark:bg-red-800" : "bg-green-200 dark:bg-green-800"}`}>
                            99% Confidence <br/>
                            {data[oldInputs["stock_b"] + "_adf_p"] <= 0.01 ? oldInputs["stock_b"] + " ~ I(0)" : oldInputs["stock_b"] + " ~ I(1)"}<br/>
                            {data[oldInputs["stock_b"] + "_adf_p"] <= 0.05 ? "H0 Rejected" : "H0 Not Rejected"}
                        </div>
                    </div>
                </div>
            </div>

            <div className="bg-slate-100 border-t-2 border-slate-500 py-3 px-5 text-sm text-slate-600 my-4 dark:text-slate-400 dark:bg-black">
                <h3 className="text-lg font-bold">Engle Granger Test</h3>

                {/* <hr className="mt-1 mb-3 border-slate-300 dark:bg-slate-800" /> */}
                
                <div className="mono">
                    <p className="text-center my-2 bg-slate-200 rounded py-2 font-bold dark:text-slate-400 dark:bg-slate-800">{oldInputs["stock_a"]}(t) = alpha + beta * {oldInputs["stock_b"]}(t)</p>
                    <p>Regression Coefficient alpha = {Math.round(data["alpha"] * 1000) / 1000}</p>
                    <p>Regression Coefficient beta = {Math.round(data["beta"] * 1000) / 1000}</p>
                    <p>Cointegration p-value = {Math.round(data["cointegration_adf_p"] * 1000) / 1000}</p>
                    <p>Rergression R^2 = {Math.round(data["rsquared"] * 100) / 100}</p>
                    <p>Regression Adjusted R^2 = {Math.round(data["rsquared_adjusted"] * 100) / 100}</p>

                    <div className="flex space-x-2 mt-2">
                        <div className={`flex-1 py-2 px-3 dark:text-slate-200 rounded ${data["cointegration_adf_p"] <= 0.05 ? "bg-green-200 dark:bg-green-800" : "bg-red-200 dark:bg-red-800"}`}>
                            95% Confidence <br/>
                            {data["cointegration_adf_p"] <= 0.05 ? "H0 Rejected" : "H0 Not Rejected"}
                        </div>
                        <div className={`flex-1 py-2 px-3 dark:text-slate-200 rounded ${data["cointegration_adf_p"] <= 0.01 ? "bg-green-200 dark:bg-green-800" : "bg-red-200 dark:bg-red-800"}`}>
                            99% Confidence <br/>
                            {data["cointegration_adf_p"] <= 0.01 ? "H0 Rejected" : "H0 Not Rejected"}
                        </div>
                    </div>
                </div>
            </div>

            <h2 className="text-xl font-semibold">
                Adjusted Closing Prices of {oldInputs["stock_a"]} and {oldInputs["stock_b"]}
            </h2>
            <p className="mb-2 text-sm">
                For the period of {oldInputs["start_date"]} to {oldInputs["end_date"]}. There are {data[oldInputs["stock_a"]]?.length} trading days in this period.
            </p>

            <TimeseriesChart show = {showData} data = {priceData} options = {options}></TimeseriesChart>
            <p className="text-xs text-gray-500 dark:text-gray-400 pt-1 mb-2"><span className="font-bold">Figure 1</span>: Adjusted Closing Prices of {oldInputs["stock_a"]} and {oldInputs["stock_b"]} for the period of {oldInputs["start_date"]} to {oldInputs["end_date"]}.</p>

            <TimeseriesChart show = {showData} data = {residualData} options = {{
                plugins: {
                    annotation: {
                        annotations: {
                            upper_std: {
                                type: 'line',
                                borderDash: [6,4],
                                borderWidth: 1,
                                yMin: data["residuals_std"],
                                yMax: data["residuals_std"],
                                borderColor: 'rgb(94,194,105)',
                            },
                            lower_std: {
                                type: 'line',
                                yMin: -data["residuals_std"],
                                yMax: -data["residuals_std"],
                                borderColor: 'rgb(94,194,105)',
                                borderDash: [6,4],
                                borderWidth: 1,
                            }
                        }
                    }}
                }}></TimeseriesChart>
                <p className="text-xs text-gray-500 dark:text-gray-400 pt-1"><span className="font-bold">Figure 2</span>: Residuals of Cointegration OLS for {oldInputs["stock_a"]} and {oldInputs["stock_b"]} for the period of {oldInputs["start_date"]} to {oldInputs["end_date"]}.</p>

                </div> 
                : ""}

                { apiError ? <div className="bg-slate-100 py-3 px-2 border-t-4 text-sm border-red-500 text-slate-600 dark:text-slate-400 dark:bg-gray-950">
                    <h4 className="text-lg text-red-600 font-medium">Something went wrong.</h4>
                    {apiError}
                </div> : "" }
            </BaseTemplate>)
}

export default AnalysingPairsTradeProject