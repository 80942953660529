import { Link } from "react-router-dom"

function Navbar(props) {
    return (
        <div className="mb-8 text-sm text-gray-500">
            <Link to={"/"} className={`${props?.currentRoute == "Home" ? "text-black dark:text-white" : ""} dark:hover:text-white hover:text-black`}>Home</Link>
            {/* <Link to={"/blog"} className={`${props?.currentRoute == "Blog" ? "text-black dark:text-white" : ""} ml-4 dark:hover:text-white hover:text-black`}>Blog</Link> */}
            <Link to={"/resume"} className={`${props?.currentRoute == "Resume" ? "text-black dark:text-white" : ""} ml-4 dark:hover:text-white hover:text-black`}>Resume</Link>
            <Link to={"/projects"} className={`${props?.currentRoute == "Projects" ? "text-black dark:text-white" : ""} ml-4 dark:hover:text-white hover:text-black`}>Projects</Link>
            {/* <Link to={"/contact"} className={`${props?.currentRoute == "Contact" ? "text-black dark:text-white" : ""} ml-4 dark:hover:text-white hover:text-black`}>Contact</Link> */}
        </div>
    )
}

export default Navbar