import BaseTemplate from "../Components/BaseTemplate"

function ContactPage(props) {
    return (
        <BaseTemplate currentRoute = "Contact">
            <h1 className='font- text-2xl'>Contact</h1>
            {/* <p className='text-gray-500'>BSc MORSE and MSc Mathematical Finance @ University of Warwick</p> */}
        </BaseTemplate>
    )
}

export default ContactPage