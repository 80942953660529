import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';

import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the Data Grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the Data Grid

import 'non.geist'

import 'non.geist/mono'

import { createBrowserRouter, RouterProvider } from "react-router-dom"

import HomePage from './Pages/HomePage';
import BlogPage from "./Pages/BlogPage";
import ResumePage from "./Pages/ResumePage";
import ProjectsPage from "./Pages/ProjectsPage"
import ContactPage from './Pages/ContactPage'
import BacktestingPairsTradeProject from './Pages/BacktestingPairsTradeProject';
import AnalysingPairsTradeProject from './Pages/AnalysingPairsTradeProject';
import ErrorPage from "./Pages/ErrorPage"
import BlogPost from './Pages/BlogPost';

const root = ReactDOM.createRoot(document.getElementById('root'));

const router = createBrowserRouter([
  { path: "/", element: <HomePage /> }, 
  { path: "/blog", element: <BlogPage /> }, 
  { path: "/blog/1", element: <BlogPost /> },
  { path: "/resume", element: <ResumePage /> }, 
  { path: "/projects", element: <ProjectsPage /> }, 
  { path: "/projects/backtesting-pairs-trade-cointegration", element: <BacktestingPairsTradeProject /> },
  { path: "/projects/analysing-pairs-trade-cointegration", element: <AnalysingPairsTradeProject /> },
  { path: "/contact", element: <ContactPage /> },
  { path: "*", element: <ErrorPage /> }
])

root.render(
  <React.StrictMode>
    
    <RouterProvider router={router} />
    {/* <App /> */}
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
